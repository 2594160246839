
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import LoadingMask from "@/components/common/LoadingMask.vue";
import { SpinnerProperties } from "@/types/spinners";
import { TabProps } from "@/types/tabs";

@Component({
  name: "Tabs",
  components: { LoadingMask },
})
export default class Tabs extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Prop({ default: "" })
  private initialActiveTabId!: string;
  @Prop({ default: "basic" })
  private theme!: "basic" | "light";
  @Prop({ default: false })
  private loading!: boolean;
  @Prop({ default: false })
  private fullWidth!: boolean;
  @Prop({ default: () => ({}) })
  private customHeaderStyles!: { [key: string]: any };
  @Prop({ default: () => ({}) })
  private customTabStyles!: { [key: string]: any };
  @Prop({ default: () => ({}) })
  private customContentWrapperStyles!: { [key: string]: any };
  @Prop()
  private tabsWrapperCssClass!: string;
  @Action("clearSubscriptionDetailsToggleTab", { namespace: "featureNotification" })
  clearSubscriptionDetailsToggleTab!: () => void;
  @Getter("subscriptionDetailsToggleTabValue", { namespace: "featureNotification" })
  subscriptionDetailsToggleTabValue!: string;

  @Watch("subscriptionDetailsToggleTabValue")
  onTabIdUpdated(tabId: string) {
    if (!!tabId) {
      setTimeout(() => {
        this.toggleTab(tabId);
      }, 0);
    }
  }

  tabs: TabProps[] = [];
  activeTabId = "";
  spinnerConfig: SpinnerProperties = {
    size: "l",
    color: "#373737",
  };

  get headerStyles() {
    let styles: { [key: string]: any } = {};
    return { ...this.customHeaderStyles };
  }

  get isLightTheme() {
    return this.theme === "light";
  }

  get tabsWrapperClassses() {
    return this.isLightTheme && ["light"];
  }

  getClassesForTab(tab: TabProps) {
    let classes: string[] = [];
    if (this.isLightTheme) {
      classes.push("light-theme");
    }
    if (tab.isActive) {
      if (this.isLightTheme) {
        classes.push("light-active");
      } else {
        classes.push("is-active");
      }
    }
    if (tab.isInDeactivation && !this.isLightTheme) {
      classes.push("is-in-deactivation");
    }
    return classes;
  }

  get tabStyles() {
    let styles: { [key: string]: any } = {};
    if (this.fullWidth || this.isMobile) {
      styles["flex"] = this.tabFlex;
    }
    if (this.isLightTheme) {
      styles["border"] = "none";
    }
    return { ...styles, ...this.customTabStyles };
  }
  get contentWrapperStyles() {
    let styles: { [key: string]: any } = {};
    return { ...this.customContentWrapperStyles };
  }

  get tabFlex(): string {
    let divider = this.tabs.filter(tab => tab.isVisible === true).length; // nb visible tab
    if (this.isMobile) {
      divider = 2;
    }
    return `0 0 ${100 / divider}%`;
  }
  get isMobile() {
    return this.$mq === "mobile";
  }

  mounted() {
    //Setting up tabs if slots are present
    if (this.$slots.tab) {
      this.tabs = this.$slots.tab.map(vnode => vnode.componentInstance as TabProps);
    }
    const tabIdChanged = this.subscriptionDetailsToggleTabValue;
    const selected = tabIdChanged || this.initialActiveTabId || this.tabs[0].id;
    this.toggleTab(selected);
  }

  toggleTab(tabId: string) {
    if (tabId) {
      this.selectTab(tabId);
      setTimeout(() => {
        this.clearSubscriptionDetailsToggleTab();
      }, 0);
    }
  }

  selectTab(id: string) {
    const selectedTab = this.findTab(id);
    if (selectedTab) {
      if (this.activeTabId !== id) {
        this.tabs.forEach(tab => {
          tab.isActive = tab.id === selectedTab.id;
          tab.isInDeactivation = tab.id === this.activeTabId;
        });
        this.$emit("tab-changed", selectedTab);
        const previousActiveTabId = this.activeTabId;
        setTimeout(() => {
          const previousActiveTab = this.tabs.find(tab => tab.id === previousActiveTabId);
          if (previousActiveTab) {
            previousActiveTab.isInDeactivation = false;
          }
        }, 300); // should be the same sleeping time that the css ::after transition
        this.activeTabId = selectedTab.id;
        selectedTab.onShow && selectedTab.onShow(selectedTab.id, selectedTab.title);
      }
    } else {
      this.$log.warn("Could not Find tab to activate that matches the id : " + id);
    }
  }

  findTab(id: string): TabProps | undefined {
    return this.tabs.find(tab => tab.id === id);
  }
}
