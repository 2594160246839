
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "Tab",
  components: {},
})
export default class Tab extends Vue {
  @Prop({ required: true })
  private id!: string;
  @Prop({ required: true })
  private title!: string;
  @Prop({ default: () => {} })
  private onShow!: () => {};
  @Prop({ default: true })
  private isVisible!: boolean;
  @Prop() private dataGtmPageTitle!: string;

  isActive = false;
  isInDeactivation = false;
}
